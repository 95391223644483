// Customize the checkbox style

// Customize the label (the container)
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  margin: 0 1rem 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Hide the browser's default checkbox
.container-checkbox input {
  display: none;
}

// Create a custom checkbox
.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid $hp-color-6;
  border-radius: 2px;
  margin: 3px 0;
}

// On mouse-over, add a grey background color
.container-checkbox:hover input~.checkmark {
  border: 1px solid lighten($component-active-bg, 25%);
}

// Create the checkmark/indicator (hidden when not checked)
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 6px;
  width: 10px;
  height: 0px;
  // border: 1px solid $hp-color-4;
  border-width: 0 0 2px 0;
}

// Show the checkmark when checked
.container-checkbox input:checked~.checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 12px;
  border: 1px solid $hp-color-4;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

