// Extra changes to BT styles.

body {
  font-family: proxima-nova,sans-serif;
  font-size: $font-size-base * 1.5; // Like h4
  color: $hp-color-2;
  line-height: 22px;
}

// Fonts
///////////////////////////////////////////////////////////////
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: objektiv-mk1,sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// Buttons
///////////////////////////////////////////////////////////////
.btn {
  font-family: objektiv-mk1,sans-serif;
}

.btn-secondary {
  border-color: $hp-color-6;
}

// Inputs
.form-control {
  font-size: $hp-font-size-base * 1.5 !important; // Aprox 14px, like h4

  &:focus {
    border-color: #33c5e1 !important;
  }
}

// Label
label {
  color: $hp-color-2;
}

.custom-select {
  height: auto;
  padding: 0.5rem 0.5rem;
}

// New styles
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

html,
body {
  height: 100%;
}

.hp-app {
  &.dragging {
    pointer-events: none;
  }

  &.no-scrollbar {
    overflow: hidden !important;
  }
}

.hp-back-link {
  color: #4b545c;
  opacity: 0.75;
}

.hp-message-error {
  color: #dc3545;
}

.hp-logo-image {
  width: 100px;
  display: inline;
}
.hp-logo-image-large {
  width: 130px;
}

.hp-thumbnail-image {
  max-width: 64px;
}

ul.hp-vertical-list {
  background-color: #fff;
  padding: 0.5rem 0;
  margin: 0;

  li {
    display: block;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: #f3f9fb;
    }
  }

  &.top-line {
    border-top: 1px solid #c8d4dd;
  }

  &.bottom-line {
    border-bottom: 1px solid #c8d4dd;
  }
}

.hp-relative {
  position: relative;
}

.hp-small-border-radius {
  border-radius: 3px;
}

.hp-align-items-center {
  align-items: center;
}

.hp-align-content-space-between {
  align-content: space-between;
}

.hp-justify-space-between {
  justify-content: space-between;
}

.hp-justify-content-end {
  justify-content: flex-end;
}

.hp-row-direction {
  flex-direction: row;
}

.hp-flex {
  display: flex;
}

.hp-flex-column {
  display: flex;
  flex-flow: column;
  height: 100%;

  > :not(.hp-flex-dynamic-height) {
    flex: 0 1 auto;
  }

  > .hp-flex-dynamic-height {
    flex: 1 1 auto;
  }
}

.hp-clickable {
  cursor: pointer;
}

.hp-draggable {
  cursor: move !important;
}

.hp-over-screen {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  overflow: scroll;
  z-index: 99;

  &.level1 {
    z-index: 999999;
  }

  &.level2 {
    z-index: 999998;
  }

  &.level3 {
    z-index: 999997;
  }
}
.hp-app-body {
  overflow: hidden;
  &.has-header {
    margin-top: 150px;
    &.has-notification {
      margin-top: 175px;
    }
  }
}

.hp-middle-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-helper {
  margin-top: 0.5rem;
  color: rgba(51, 55, 58, 0.75); // #33373A with 75% opacity
  font-size: 0.75rem; // 12px (typically 1rem = 16px)
}

.hp-fab-button {
  background-color: $hp-color-5;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 36px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: 0.3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  position: fixed;
  z-index: 101;
  top: 74px;
  right: 1.5rem;
  bottom: -30px;
}

.hp-fab-button:focus,
.hp-fab-button-active:focus {
  outline: none;
}

.hp-fab-button-active {
  outline: none;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background-color: $hp-color-3;
  z-index: 100;
}

.hp-with-fab-button {
  position: relative;
  margin-bottom: 30px; // fab button has 60px
}

.hp-page-wrapper {
  display: block;
  padding: 0 1.5rem 5rem;
  max-width: 1120px;
  margin: 0 auto;

  &.no-padding {
    padding: 0;
  }
}

.hp-section-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  padding: 24px 48px;
  padding-bottom: 0;
}

.hp-section-column {
  flex: 1;
  max-width: 50%;
}

.hp-widget-title {
  text-align: center;

  &--label {
    font-size: 14px;
    color: #33373a;
    opacity: 0.5;
  }
}

.hp-section-title {
  font-size: 18px;
  margin-bottom: 14px;
}

.hp-menu-icon-wrapper {
  position: relative;
  background: $hp-color-3;
  border-radius: 50%;
  overflow: hidden;
}

.hp-shared-options-list {
  display: none;
  position: absolute;
  border: 1px solid $hp-color-6;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
  top: calc(100% + 5px);
  padding: 0;
  right: -20px;
  width: 160px;
  z-index: 200;

  &.visible {
    display: block;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      background-color: #fff;
      display: block;
      font-size: 14px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      height: 32px;
      line-height: 32px;
      padding-left: 16px;

      &:hover {
        background-color: #f3f9fb;
      }
    }
  }
}

.hp-menu-icon-wrapper {
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.navbar-items-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 48px;
}

.hp-section-heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.hp-selected-date {
  font-size: 14px;
  margin-right: 4px;
  margin-bottom: 0;
  line-height: 1;
}

.overlay-illustration-wrapper {
  position: absolute;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.illustration-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 192px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto 32px;
}

.illustration-text-content {
  font-size: 14px;
}

::ng-deep ngb-datepicker {
  box-shadow: none;
  outline: none;

  .ngb-dp-month-name.d-block {
    font-size: 14px;
    font-weight: 500;
  }

  .ngb-dp-navigation-chevron:before {
    color: $input-placeholder-color;
  }

  .ngb-dp-header.bg-light,
  .ngb-dp-week.ngb-dp-weekdays.bg-light {
    background: #fff !important;
    border: none;
  }

  .ngb-dp-weekday.small.text-center.font-italic {
    font-size: 12px;
    font-style: normal !important;
    color: $input-placeholder-color;
  }

  .ngb-dp-day {
    font-size: 14px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.file-picker {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.subcategories-zone {
  background-color: $drop-zone-bg;

  .drop-zone {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 90px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    border: 2px solid $drop-zone-bg;
    cursor: default !important;
    font-size: 12px;
  }
}

.drop-zone-active .drop-zone {
  border: 2px dashed $drop-zone-border;
}

.drop-zone-not-active {
  border: 2px solid $drop-zone-bg !important;
}

.doc-button {
  position: relative;
  background: $hp-color-7;
  border: 1px solid $hp-color-6;
  border-radius: 2px;
  padding: 3px 8px;
  font-size: 14px;
  margin-left: 16px;
  cursor: pointer;

  ::ng-deep hp-shared-icon {
    opacity: 0.75;
  }
}

.hp-avatar-button {
  cursor: pointer;

  .icon {
    bottom: 0;
    right: 0;
  }
}

.input-validation-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  line-height: 24px;
}

// animations
.hp-spinning {
  animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
