// Assign some variables to :root (colors), like Bootstrap does.
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

$hp-colors: () !default;
$hp-colors: map-merge((
  "hp-primary-color": $hp-color-3,
  "hp-secondary-color": $hp-color-7,
  "hp-color-1": $hp-color-1,
  "hp-color-2": $hp-color-2,
  "hp-color-3": $hp-color-3,
  "hp-color-4": $hp-color-4,
  "hp-color-5": $hp-color-5,
  "hp-color-6": $hp-color-6,
  "hp-color-7": $hp-color-7,
  "hp-category-color-1": $hp-category-color-1,
  "hp-category-color-2": $hp-category-color-2,
  "hp-category-color-3": $hp-category-color-3,
  "hp-category-color-4": $hp-category-color-4,
  "hp-category-color-5": $hp-category-color-5,
  "hp-category-color-6": $hp-category-color-6,
  "hp-color-orange": $hp-category-color-1,
  "hp-color-green": $hp-category-color-3,
  "hp-color-red": $hp-category-color-6,
  "hp-color-blue": $hp-category-color-4,
  "hp-color-yellow": $hp-category-color-2,
  "hp-color-purple": $hp-category-color-5
), $hp-colors);


:root {
  @each $color, $value in $hp-colors {
    --#{$color}: #{$value};
  }
}
