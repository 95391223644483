.tooltip {
  font-size: 12px;
  font-weight: 300;

  .arrow {
    display: none !important;
  }

  .tooltip-inner {
    background-color: #999b9c;
  }
}
