// Creating new theme variables
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////


// Colors
$hp-color-1: #161717;
$hp-color-2: #33373A;
$hp-color-3: #137082;
$hp-color-4: #1890A7;
$hp-color-5: #FF4081;
$hp-color-6: #C8D4DD;
$hp-color-7: #E7F1F3;
$hp-color-8: #F3F9FB;
$hp-color-error: #DF3A1F;
$hp-color-warning: #F3DD58;

// Category colors
$hp-category-color-0: #2BC24C;
$hp-category-color-1: #E67E22;
$hp-category-color-2: #F1C40F;
$hp-category-color-3: #1ABC9C;
$hp-category-color-4: #3498DB;
$hp-category-color-5: #9B59B6;
$hp-category-color-6: #E74C3C;

// Fonts
$hp-font-family-roboto: 'Roboto';
$hp-font-size-base: 0.7rem;


// Replace values for Bootstrap theme used variables
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////


// Base variables
///////////////////////////////////////////////////////////////

// Border
$border-radius-sm: 2px;
$border-radius: 2px;
$border-radius-lg: 2px;

// Fonts
$font-family-base: $hp-font-family-roboto;
$font-size-base: $hp-font-size-base;

// Specific elements variables
///////////////////////////////////////////////////////////////


// Divider (hr)
$hr-border-color: $hp-color-6;
$hr-border-width: 2px;

// Cards
$card-cap-bg: $hp-color-7;
$card-border-color: $hp-color-6;

// Inputs
$input-border-color: $hp-color-6;

$input-btn-padding-y: .5rem; // 8px (typically 1rem = 16px)
$input-btn-padding-x: .5rem; // 8px (typically 1rem = 16px)

$input-placeholder-color: rgba(51, 55, 58, 0.5); // #33373A with 50% opacity

$drop-zone-bg: #F5F9FA;
$drop-zone-border: $hp-color-6;
